@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&display=swap');
.g{  
  padding-left: 30rem;
  margin-bottom: 3rem;
}
.road_typo2 {
  font-family: 'Josefin Sans', sans-serif  !important;
padding:10px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 32px !important;
  line-height: 32px !important;
  
  text-decoration: underline;
  color: #ffffff;
}
.grid-media-left {
  justify-content: center;
}
.road_typo3 {
  font-family: 'Josefin Sans', sans-serif  !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 14px !important;
  line-height: 20px !important;
 
  color: gray !important;
  
}
.road_typo4 {
  font-family: 'Josefin Sans', sans-serif  !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 14px !important;
  line-height: 20px !important;


  /* text-align: right; */

  color: #ffffff !important;
}
.nft_grid2 {
  margin-top: 100px;
  padding:20px !important
  
}
.nft_grid3{
  margin-top: 120px;
}
.road_typo6 {
  font-family: 'Josefin Sans', sans-serif  !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  
  color: gray !important;
  
}
.road_typo7 {
  font-family: 'Josefin Sans', sans-serif  !important;

  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 14px !important;
  line-height: 20px !important;

  color: #ffffff !important;
}
.road_typo8 {
  font-family: 'Josefin Sans', sans-serif  !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 16px !important;

  color: #a52f2f;
}
.nft_grid3 {
  margin-top: 140px;
}
.rm_lo {
  margin-top: 140px !important;
  margin-bottom: 60px !important;
}
@media (max-width: 462px) {
  .grid-media-left {
    display: grid !important;
    justify-content: flex-start !important;
    margin-top: 20px !important ;
  }
  .nft_grid2 {
    margin-top: 20px !important;
   
  }
  
}
.nft_main_grid2{
  margin-top:-20px !important
}
@media (max-width: 600px) {
  .nft_grid3 {
    margin-top: 20px;
  }
  .nft_grid2 {
    margin-top: 0 !important;
    
  }
  .nft_main_grid2{
    margin-top:25px !important
  }
  .nft_grid {
    margin-top: 20px;
    text-align: left;
  }
  .nft_main_grid{
margin-top:5px !important
  }
  .road_typo4 {
    text-align: left;
  }
  .road_typo2 {
    
display: none;
    font-size: 20px !important;
  }
  .rm_lo {
    display: none;
  }
  .road_typo {
    font-size: 22px !important;
  }
}


.mainroad {
  min-width: 300px;
  max-width: 500px;
  margin: auto;
    padding: 20px;
}
.road_ypo {
   font-family: 'Roboto', sans-serif !important;
   font-style: normal !important;
   font-weight: bold !important;
   font-size: 42px !important;
   line-height: 56px !important;
 /* padding-right: 50px; */
 padding: 20px;
   color: #ffffff;
 }
 .card{
  background: linear-gradient(
    360deg,
    #000000 0%,
    #000000 0.01%,
    rgba(59, 59, 59, 0.61) 80.73%
  ) !important;
  border-radius: 15px !important;
  margin-top: 100px !important;
  padding: "20px" !important;
  height: auto !important;
 }
.ns {
  font-size: 1em;
  line-height: 1.75em;
  border-top: 3px solid;
  border-image: linear-gradient(to right,  #7a1818 0%, #6b6c7e 100%);
  border-image-slice: 1;
  border-width: 5px;
  margin: 0;
  padding: 40px;

  counter-increment: section;
  position: relative;
  color: #eaedf1;
}
.ns:before {
  content: counter(section);
  position: absolute;
  border-radius: 50%;
  padding: 10px;
  height: 1.5em;
  width: 1.5em;
  background-color: #2f3c58;
  text-align: center;
  line-height: 1.25em;
  color: #fff;
  font-size: 1em;
}
.ns:nth-child(odd) {
  border-right: 3px solid;
  padding-left: 0;
  text-align: right;
  
 
}
.ns:nth-child(odd):before {
  left: 100%;
  margin-left: -20px;
}
.ns:nth-child(even) {
  border-left: 3px solid;
  padding-right: 0;
 
  
}
.ns:nth-child(even):before {
  right: 100%;
  margin-right: -20px;
}
.ns:first-child {
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.ns:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

