.dropdown{
    text-decoration: none !important;
    color: white;
    padding: 8px 8px;
    float: left; 
}
.textcolor{
color: white;
}

.MuiList-root{
  background-color: #3b3b3b;
}