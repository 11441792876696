.modal_typo1 {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 25px !important;
  line-height: 35px !important;

  color: #ffffff;
}
.term{
  width:400px;
  padding: 10px 20px
}
.term_heading{
  color: #fff;

}
.term_para{
  color: #fff;
  line-height: 1.7rem;
}
.modal_typo2 {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 28px !important;
  color: #ffffff;
}
.modal_typo3 {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 35px !important;
  text-align: right !important;

  /* line-height: 72px !important; */

  color: #ffffff;
}
.modal_typo4 {
  font-family: Roboto !important;
  font-style: italic !important;
  font-weight: normal !important;
  font-size: 14px !important;
  /* line-height: 21px; */
  margin: 5px !important;
  text-align: center !important;
  color: #ffffff;
}
.modal_typo5 {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 13px !important;
  /* line-height: 19px; */
  text-align: center;
  color: #ffffff;
}
.modal_typo6 {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 26.9231px !important;
  /* line-height: 32px; */

  color: #ffffff;
}

@media (max-width: 959px) {
  .chk > div {
    max-width: 100%;
  }
}

@media (max-width: 600px) {
  .slick2Img {
    height: 200px;
  }
  .eth {
    width: 30px;
  }
}
