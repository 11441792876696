.MuiSvgIcon-root {
  color: black;
}
.logo {
  margin-top: 20px;
  margin-left: -35px;
}
.tt {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 18px !important;
  line-height: 21px !important;
  text-decoration: none !important;
  color: #ffffff;
  justify-content: space-between !important;
  display: contents !important;
  font-family: 'Roboto', sans-serif;  
}


.anchor {
  font-family: 'Roboto', sans-serif !important;
  text-decoration: none;
  color: white;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 18px !important;
  line-height: 21px !important;
}

.last {

  cursor: pointer;
  /* margin-right: 4rem !important;
  margin-left: 3rem !important; */
  /* margin-top: 1.7rem !important; */
  display: flex;
}
.navbar1 {
  padding: 10px;
  cursor: pointer;
  margin-left: 1.3rem !important;
  justify-content: center !important;
  align-items: center !important;
    display: flex !important;
}
.discord_icons{
  padding-left: 1rem !important;  
}

.navbar1:hover,
.navbar1:focus,
.navbar1:active {
  font-family: 'Roboto' !important;

  -webkit-transform: scale(1.1);
  transform: scale(0.9);
}
.MuiContainer-root{
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.icon_w {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 5px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.header_bar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  margin-right: 26px;
  margin-left: 26px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  background-color: rgba(15, 15, 15, 0);
}
.image-2 {
  max-height: 30px;
  max-width: 100%;
  min-height: 0px;
  cursor: pointer;
}
@media (max-width: 600px) {
  .sectionMobile {
    display: none;
  }
}
@media (max-width: 954px) {
  .anchor {
    color: white;
  }
  .logo {
    margin-left: 0;
  }
}
.react-switch-bg {
  height: 30px !important;
}
