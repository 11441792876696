@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}
body{
  background-color: #000000;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.modal_typo1 {
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: 900 !important;
    font-size: 25px !important;
    line-height: 35px !important;
    color: #ffffff;
  }
  .classes-modal {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .classes-paper {
    background: rgba(255, 255, 255, 0.9);
    background-color: black;
    width: auto;
    height: auto;
   
  }
  .modal_typo2 {
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 15px !important;
    line-height: 28px !important;
    color: #ffffff;
  }
  .modal_typo3 {
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: 900 !important;
    font-size: 35px !important;
    text-align: right !important;
    color: #ffffff;
  }
  .modal_typo4 {
    font-family: Roboto !important;
    font-style: italic !important;
    font-weight: normal !important;
    font-size: 14px !important;
    margin: 5px !important;
    text-align: center !important;
    color: #ffffff;
  }
  .modal_typo5 {
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 13px !important;
    text-align: center;
    color: #ffffff;
  }
  .modal_typo6 {
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: 900 !important;
    font-size: 26.9231px !important;
    color: #ffffff;
  }
 
  
  @media (max-width: 959px) {
    .chk > div {
      max-width: 100% !important;
      max-height: 100% !important;
      width: auto !important;
    height: auto !important;
    }
  }
  
  @media (max-width: 600px) {
    .slick2Img {
      height: 200px;
    }
    .eth {
      width: 30px;
    }
  }
  
.dropdown{
    text-decoration: none !important;
    color: white;
    padding: 8px 8px;
    float: left; 
}
.textcolor{
color: white;
}

.MuiList-root{
  background-color: #3b3b3b;
}
.colors{background: linear-gradient(
    180deg,
    #741717 0%,
    rgba(165, 47, 47, 0.872093) 100%
  );
  color: white !important;
}
.titlec{
  color: white;
}
.MuiSvgIcon-root {
  color: black;
}
.logo {
  margin-top: 20px;
  margin-left: -35px;
}
.tt {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 18px !important;
  line-height: 21px !important;
  text-decoration: none !important;
  color: #ffffff;
  justify-content: space-between !important;
  display: contents !important;
  font-family: 'Roboto', sans-serif;  
}


.anchor {
  font-family: 'Roboto', sans-serif !important;
  text-decoration: none;
  color: white;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 18px !important;
  line-height: 21px !important;
}

.last {

  cursor: pointer;
  /* margin-right: 4rem !important;
  margin-left: 3rem !important; */
  /* margin-top: 1.7rem !important; */
  display: flex;
}
.navbar1 {
  padding: 10px;
  cursor: pointer;
  margin-left: 1.3rem !important;
  justify-content: center !important;
  align-items: center !important;
    display: flex !important;
}
.discord_icons{
  padding-left: 1rem !important;  
}

.navbar1:hover,
.navbar1:focus,
.navbar1:active {
  font-family: 'Roboto' !important;
  transform: scale(0.9);
}
.MuiContainer-root{
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.icon_w {
  display: flex;
  padding-right: 5px;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
}
.header_bar {
  display: flex;
  height: 40px;
  margin-right: 26px;
  margin-left: 26px;
  flex-direction: row-reverse;
  justify-content: flex-start;
  background-color: rgba(15, 15, 15, 0);
}
.image-2 {
  max-height: 30px;
  max-width: 100%;
  min-height: 0px;
  cursor: pointer;
}
@media (max-width: 600px) {
  .sectionMobile {
    display: none;
  }
}
@media (max-width: 954px) {
  .anchor {
    color: white;
  }
  .logo {
    margin-left: 0;
  }
}
.react-switch-bg {
  height: 30px !important;
}

.g{  
  padding-left: 30rem;
  margin-bottom: 3rem;
}
.road_typo2 {
  font-family: 'Josefin Sans', sans-serif  !important;
padding:10px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 32px !important;
  line-height: 32px !important;
  
  text-decoration: underline;
  color: #ffffff;
}
.grid-media-left {
  justify-content: center;
}
.road_typo3 {
  font-family: 'Josefin Sans', sans-serif  !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 14px !important;
  line-height: 20px !important;
 
  color: gray !important;
  
}
.road_typo4 {
  font-family: 'Josefin Sans', sans-serif  !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 14px !important;
  line-height: 20px !important;


  /* text-align: right; */

  color: #ffffff !important;
}
.nft_grid2 {
  margin-top: 100px;
  padding:20px !important
  
}
.nft_grid3{
  margin-top: 120px;
}
.road_typo6 {
  font-family: 'Josefin Sans', sans-serif  !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  
  color: gray !important;
  
}
.road_typo7 {
  font-family: 'Josefin Sans', sans-serif  !important;

  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 14px !important;
  line-height: 20px !important;

  color: #ffffff !important;
}
.road_typo8 {
  font-family: 'Josefin Sans', sans-serif  !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 16px !important;

  color: #a52f2f;
}
.nft_grid3 {
  margin-top: 140px;
}
.rm_lo {
  margin-top: 140px !important;
  margin-bottom: 60px !important;
}
@media (max-width: 462px) {
  .grid-media-left {
    display: grid !important;
    justify-content: flex-start !important;
    margin-top: 20px !important ;
  }
  .nft_grid2 {
    margin-top: 20px !important;
   
  }
  
}
.nft_main_grid2{
  margin-top:-20px !important
}
@media (max-width: 600px) {
  .nft_grid3 {
    margin-top: 20px;
  }
  .nft_grid2 {
    margin-top: 0 !important;
    
  }
  .nft_main_grid2{
    margin-top:25px !important
  }
  .nft_grid {
    margin-top: 20px;
    text-align: left;
  }
  .nft_main_grid{
margin-top:5px !important
  }
  .road_typo4 {
    text-align: left;
  }
  .road_typo2 {
    
display: none;
    font-size: 20px !important;
  }
  .rm_lo {
    display: none;
  }
  .road_typo {
    font-size: 22px !important;
  }
}


.mainroad {
  min-width: 300px;
  max-width: 500px;
  margin: auto;
    padding: 20px;
}
.road_ypo {
   font-family: 'Roboto', sans-serif !important;
   font-style: normal !important;
   font-weight: bold !important;
   font-size: 42px !important;
   line-height: 56px !important;
 /* padding-right: 50px; */
 padding: 20px;
   color: #ffffff;
 }
 .card{
  background: linear-gradient(
    360deg,
    #000000 0%,
    #000000 0.01%,
    rgba(59, 59, 59, 0.61) 80.73%
  ) !important;
  border-radius: 15px !important;
  margin-top: 100px !important;
  padding: "20px" !important;
  height: auto !important;
 }
.ns {
  font-size: 1em;
  line-height: 1.75em;
  border-top: 3px solid;
  border-image: linear-gradient(to right,  #7a1818 0%, #6b6c7e 100%);
  border-image-slice: 1;
  border-width: 5px;
  margin: 0;
  padding: 40px;

  counter-increment: section;
  position: relative;
  color: #eaedf1;
}
.ns:before {
  content: counter(section);
  position: absolute;
  border-radius: 50%;
  padding: 10px;
  height: 1.5em;
  width: 1.5em;
  background-color: #2f3c58;
  text-align: center;
  line-height: 1.25em;
  color: #fff;
  font-size: 1em;
}
.ns:nth-child(odd) {
  border-right: 3px solid;
  padding-left: 0;
  text-align: right;
  
 
}
.ns:nth-child(odd):before {
  left: 100%;
  margin-left: -20px;
}
.ns:nth-child(even) {
  border-left: 3px solid;
  padding-right: 0;
 
  
}
.ns:nth-child(even):before {
  right: 100%;
  margin-right: -20px;
}
.ns:first-child {
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.ns:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}


.main-grid{
    display: flex !important;
    align-items: center !important;
    justify-content: right !important;
}
.App-header{
    position:relative !important;
    z-index: 1 !important;
}
.button-grid{
   justify-content: right !important;
}
.new{
    position: absolute !important;
    top: 0% !important;
    width: 100% !important;
    height: 100% !important;
    left: 0 !important;
    bottom: 40% !important;
}
.footer-text{
   align-items:flex-end !important;
   height: 3rem !important;
   background: linear-gradient(91.9deg, #222329 1.74%, #2a353a 90.07%);    
}
.ape-text{
    font-size: 0.89rem !important;
    color: #C8CCD8;
    justify-content: right !important;
}
.logo-grid{
    margin-left: 5.5rem !important;
    justify-content: right !important;
}
.mint-button{
    cursor: "pointer";
    color: white !important;
    text-decoration: none !important;    
}

@media (max-width: 400px) {
    .logo-grid{
       margin: 0% !important;     
    }
  }
.modal_typo1 {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 25px !important;
  line-height: 35px !important;

  color: #ffffff;
}
.term{
  width:400px;
  padding: 10px 20px
}
.term_heading{
  color: #fff;

}
.term_para{
  color: #fff;
  line-height: 1.7rem;
}
.modal_typo2 {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 28px !important;
  color: #ffffff;
}
.modal_typo3 {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 35px !important;
  text-align: right !important;

  /* line-height: 72px !important; */

  color: #ffffff;
}
.modal_typo4 {
  font-family: Roboto !important;
  font-style: italic !important;
  font-weight: normal !important;
  font-size: 14px !important;
  /* line-height: 21px; */
  margin: 5px !important;
  text-align: center !important;
  color: #ffffff;
}
.modal_typo5 {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 13px !important;
  /* line-height: 19px; */
  text-align: center;
  color: #ffffff;
}
.modal_typo6 {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 26.9231px !important;
  /* line-height: 32px; */

  color: #ffffff;
}

@media (max-width: 959px) {
  .chk > div {
    max-width: 100%;
  }
}

@media (max-width: 600px) {
  .slick2Img {
    height: 200px;
  }
  .eth {
    width: 30px;
  }
}

.foot_main {
  background-color: black !important;
  margin-top: 155px !important;
  padding: 40px;
  display: flex;
  flex-direction: column;
}
.icons_f {
  display: flex;
}
.footer_icons {
  /* display: flex; */
  /* justify-content: space-evenly; */
}
.footer_cont {
  color: white;
  text-align: right;
}
.bnavlinks {
  cursor: pointer;

  /* display: inline-block; */
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: transform;
}
.bnavlinks:hover,
.bnavlinks:focus,
.bnavlinks:active {
  transform: scale(0.9);
}
.terms {
  color: white;
  margin-top: 60px !important;
  text-align: center;
}
.f_icons {
  margin-left: 15px;
  cursor: pointer;
  width: 40px;
}
.terms2 {
  color: white;
  text-align: center;
  /* margin-top: 20px !important; */
}
.dnaa_imgg {
  /* margin-left: -40px; */
  cursor: pointer;
  align-items: center;
}
.f_icons {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: transform;
}
.f_icons:hover,
.f_icons:focus,
.f_icons:active {
  transform: scale(0.9);
}

.icons {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: transform;
  padding: 5px;
}
.icons_icons {
  width: 40px;
  cursor: pointer;
}
.icons:hover,
.icons:focus,
.icons:active {
  transform: scale(0.9);
}
.dnaa_img_wrap {
  display: flex;
  justify-content: center;
}

@media (max-width: 600px) {
  .footer_cont {
    text-align: center;
    margin-top: 20px !important;
    color:white !important
  }
  .terms {
    text-align: center;
  }
  .f_icons {
    margin-left: 5px;
    margin-top: 30px;
    width: 30px;
  }
  .terms2 {
    text-align: center;
    
  }
  .dnaa_imgg {
    margin-left: 0;
    width: 100%;
  }
}


