.foot_main {
  background-color: black !important;
  margin-top: 155px !important;
  padding: 40px;
  display: flex;
  flex-direction: column;
}
.icons_f {
  display: flex;
}
.footer_icons {
  /* display: flex; */
  /* justify-content: space-evenly; */
}
.footer_cont {
  color: white;
  text-align: right;
}
.bnavlinks {
  cursor: pointer;

  /* display: inline-block; */
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.bnavlinks:hover,
.bnavlinks:focus,
.bnavlinks:active {
  -webkit-transform: scale(1.1);
  transform: scale(0.9);
}
.terms {
  color: white;
  margin-top: 60px !important;
  text-align: center;
}
.f_icons {
  margin-left: 15px;
  cursor: pointer;
  width: 40px;
}
.terms2 {
  color: white;
  text-align: center;
  /* margin-top: 20px !important; */
}
.dnaa_imgg {
  /* margin-left: -40px; */
  cursor: pointer;
  align-items: center;
}
.f_icons {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.f_icons:hover,
.f_icons:focus,
.f_icons:active {
  -webkit-transform: scale(1.1);
  transform: scale(0.9);
}

.icons {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  padding: 5px;
}
.icons_icons {
  width: 40px;
  cursor: pointer;
}
.icons:hover,
.icons:focus,
.icons:active {
  -webkit-transform: scale(1.1);
  transform: scale(0.9);
}
.dnaa_img_wrap {
  display: flex;
  justify-content: center;
}

@media (max-width: 600px) {
  .footer_cont {
    text-align: center;
    margin-top: 20px !important;
    color:white !important
  }
  .terms {
    text-align: center;
  }
  .f_icons {
    margin-left: 5px;
    margin-top: 30px;
    width: 30px;
  }
  .terms2 {
    text-align: center;
    
  }
  .dnaa_imgg {
    margin-left: 0;
    width: 100%;
  }
}

