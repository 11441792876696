.main-grid{
    display: flex !important;
    align-items: center !important;
    justify-content: right !important;
}
.App-header{
    position:relative !important;
    z-index: 1 !important;
}
.button-grid{
   justify-content: right !important;
}
.new{
    position: absolute !important;
    top: 0% !important;
    width: 100% !important;
    height: 100% !important;
    left: 0 !important;
    bottom: 40% !important;
}
.footer-text{
   align-items:flex-end !important;
   height: 3rem !important;
   background: linear-gradient(91.9deg, #222329 1.74%, #2a353a 90.07%);    
}
.ape-text{
    font-size: 0.89rem !important;
    color: #C8CCD8;
    justify-content: right !important;
}
.logo-grid{
    margin-left: 5.5rem !important;
    justify-content: right !important;
}
.mint-button{
    cursor: "pointer";
    color: white !important;
    text-decoration: none !important;    
}

@media (max-width: 400px) {
    .logo-grid{
       margin: 0% !important;     
    }
  }